import { Button, Modal } from "semantic-ui-react";

import { SelectedCarrier } from "models/SelectedCarrier";
import CarrierCapacityShipmentMatchTable from "./CarrierCapacityShipmentMatchTable";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";

type CarrierCapacityShipmentMatchModalProps = {
  carrier: SelectedCarrier;
  capacities: CarrierCapacityInfoDto<"Capacity">[];
  isLoadingCapacities: boolean;
  onClose: () => void;
};

const CarrierCapacityShipmentMatchModal = (
  props: CarrierCapacityShipmentMatchModalProps
) => {
  return (
    <Modal size="fullscreen" open onClose={props.onClose}>
      <Modal.Header>Suggested Shipments - {props.carrier.name}</Modal.Header>

      <Modal.Content scrolling>
        <CarrierCapacityShipmentMatchTable {...props} />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" negative onClick={props.onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarrierCapacityShipmentMatchModal;
