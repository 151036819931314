import axios, { AxiosError } from "axios";
import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { Placeholder, Table } from "semantic-ui-react";

import { SelectedCarrier } from "models/SelectedCarrier";
import CarrierCapacityShipmentMatchCapacityTableRow from "./CarrierCapacityShipmentMatchCapacityTableRow";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import CarrierCapacityShipmentMatchTableRow from "./CarrierCapacityShipmentMatchTableRow";
import { CarrierCapacityShipmentMatchDto } from "models/dto/CarrierCapacityShipmentMatch";

import styles from "./CarrierCapacityShipmentMatchTable.module.scss";

export const shipmentMatchColSpan = 10;

export type CapacityMatchStatus = {
  isLoading: boolean;
  matches: CarrierCapacityShipmentMatchDto[];
};

type CarrierCapacityShipmentMatchTableProps = {
  carrier: SelectedCarrier;
  capacities: CarrierCapacityInfoDto<"Capacity">[];
  isLoadingCapacities: boolean;
};

const CarrierCapacityShipmentMatchTable = (
  props: CarrierCapacityShipmentMatchTableProps
) => {
  const [capacityMatchMap, setCapacityMatchMap] = useState<
    Record<number, CapacityMatchStatus>
  >({});

  const {
    carrier: { id: carrierId },
    capacities,
  } = props;
  const fetchCapacityShipmentMatches = useCallback(() => {
    setCapacityMatchMap(
      capacities.reduce<Record<number, CapacityMatchStatus>>(
        (acc, capacity) => {
          if (capacity.id) {
            acc[capacity.id] = { isLoading: true, matches: [] };
          }
          return acc;
        },
        {}
      )
    );

    for (const capacity of capacities) {
      const capacityId = capacity.id; // TS is not picking up that this will NOT be null below without reassignment ???

      if (capacityId) {
        const url = `/api/carriers/${carrierId}/capacities/${capacityId}/shipmentMatches`;

        axios
          .get<CarrierCapacityShipmentMatchDto[]>(url)
          .then(({ data }) => {
            setCapacityMatchMap((previousCapacityMatchMap) => ({
              ...previousCapacityMatchMap,
              [capacityId]: { isLoading: false, matches: data },
            }));
          })
          .catch((error) => {
            console.error((error as AxiosError).message);

            setCapacityMatchMap((previousCapacityMatchMap) => ({
              ...previousCapacityMatchMap,
              [capacityId]: { isLoading: false, matches: [] },
            }));
          });
      }
    }
  }, [carrierId, capacities, setCapacityMatchMap]);

  useEffect(() => {
    fetchCapacityShipmentMatches();
  }, [fetchCapacityShipmentMatches]);

  const sortedCapacityIds = useMemo(
    () =>
      Object.entries(capacityMatchMap).reduce<{
        matches: number[];
        noMatches: number[];
        pending: number[];
      }>(
        (acc, [capacityId, { isLoading, matches }]) => {
          if (isLoading) {
            acc.pending.push(Number(capacityId));
          } else if (matches.length > 0) {
            acc.matches.push(Number(capacityId));
          } else {
            acc.noMatches.push(Number(capacityId));
          }

          return acc;
        },
        { matches: [], noMatches: [], pending: [] }
      ),
    [capacityMatchMap]
  );

  const totalShipmentCount = useMemo(
    () =>
      Object.values(capacityMatchMap).reduce<Set<number>>(
        (acc, { matches }) => {
          matches.forEach((match) => {
            acc.add(match.tmsShipmentId);
          });
          return acc;
        },
        new Set()
      ).size,
    [capacityMatchMap]
  );

  return (
    <Table singleLine className={styles.shipmentMatchTable}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            colSpan={shipmentMatchColSpan}
            className={styles.shipmentCount}
          >
            {`${totalShipmentCount} Shipments Found`}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Shipment ID</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Equipment</Table.HeaderCell>
          <Table.HeaderCell>Route</Table.HeaderCell>
          <Table.HeaderCell>Route Appointment(s)</Table.HeaderCell>
          <Table.HeaderCell>Cap Quote</Table.HeaderCell>
          <Table.HeaderCell>Max Pay</Table.HeaderCell>
          <Table.HeaderCell>Customer(s)</Table.HeaderCell>
          <Table.HeaderCell>Item Count</Table.HeaderCell>
          <Table.HeaderCell>Items</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedCapacityIds.matches.map((capacityId) => {
          const capacity = capacities.find(
            (capacity) => capacity.id === capacityId
          );

          return capacity ? (
            <Fragment key={capacityId}>
              <CarrierCapacityShipmentMatchCapacityTableRow
                capacity={capacity}
              />

              {capacityMatchMap[capacityId].matches.map((shipmentMatch) => (
                <CarrierCapacityShipmentMatchTableRow
                  key={shipmentMatch.tmsShipmentId}
                  shipmentMatch={shipmentMatch}
                  capacity={capacity}
                />
              ))}
            </Fragment>
          ) : null;
        })}

        {sortedCapacityIds.noMatches.length > 0 &&
        sortedCapacityIds.pending.length === 0
          ? sortedCapacityIds.noMatches.map((capacityId) => {
              const capacity = capacities.find(
                (capacity) => capacity.id === capacityId
              );

              return capacity ? (
                <CarrierCapacityShipmentMatchCapacityTableRow
                  key={capacityId}
                  capacity={capacity}
                  message="No Suggested Shipments"
                />
              ) : null;
            })
          : null}

        {props.isLoadingCapacities || sortedCapacityIds.pending.length > 0 ? (
          <Table.Row>
            {[...Array(shipmentMatchColSpan).keys()].map((value) => (
              <Table.Cell
                key={`${value}-loading-row`}
                className={styles.loadingRow}
              >
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Table.Cell>
            ))}
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>
  );
};

export default CarrierCapacityShipmentMatchTable;
